// src/components/ProductSlider.js
import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ProductSlider.css';

const products = [
    {
        name: 'INSULIN RESISTANCE',
        image: './images/ins1.jpg',
        description: 'Vitamin and mineral blend',
    },
    {
        name: 'OPTIMUM IMMUNE',
        image: './images/opim.jpg',
        description: 'Vitamin and mineral blend',
    },
    {
        name: 'SUPERIOR SLEEP',
        image: './images/sleep1.jpg',
        description: 'Vitamin and mineral blend',
    },
    {
        name: 'Supplements for Males',
        image: './images/man1.jpg',
        description: 'Vitamin and mineral blends - click on the image to explore further',
    },
    {
        name: 'Supplements for Females',
        image: './images/wom1.jpg',
        description: 'Vitamin and mineral blends - click on the image to explore further',
    },
    {
        name: 'AMINO BLENDS',
        image: './images/ab.jpg',
        description: 'Explore our vast category of Amino Blends supplements',
    },
    {
        name: 'OPTIMUM IMMUNE',
        image: './images/opi2.jpg',
        description: 'Amino Blend Version',
    },
    {
        name: 'SUPERIOR SLEEP',
        image: './images/sup2.jpg',
        description: 'Amino Blend Version',
    },
    {
        name: 'Amino Blends for Males',
        image: './images/man2.jpg',
        description: 'Amino Blends for Males - click the image to explore further',
    },
    {
        name: 'Amino Blends for Females',
        image: './images/wom2.jpg',
        description: 'Amino Blends for Females - click the image to explore further',
    },

    // Add more products if needed
];

const ProductSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <Slider {...settings} className="product-slider">
            {products.map((product, index) => (
                <div key={index} className="product-slide">
                    <img src={product.image} alt={product.name} className="product-image" />
                    <h3 className="product-name">{product.name}</h3>
                    <p className="product-description">{product.description}</p>
                </div>
            ))}
        </Slider>
    );
};

export default ProductSlider;
