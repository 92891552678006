// src/App.js
import React from 'react';
import ProductSlider from './components/ProductSlider';
import './index.css';
import './App.css';

function App() {
    return (
        <div className="app-container">
            <nav className="navbar">
                <div className="particle-container">
                    <svg className="particle-svg" viewBox="0 0 200 200">
                        <circle className="particle-node" cx="50" cy="50" r="5" />
                        <circle className="particle-node" cx="100" cy="100" r="5" />
                        <circle className="particle-node" cx="150" cy="50" r="5" />
                        <line x1="50" y1="50" x2="100" y2="100" className="particle-line" />
                        <line x1="100" y1="100" x2="150" y2="50" className="particle-line" />
                    </svg>
                </div>
                <ul className="nav-links">
                    <li><a href="#supplements">Supplements</a></li>
                    <li><a href="#health-services/consultations">Health Services</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                </ul>
                <div className="particle-container">
                    <svg className="particle-svg" viewBox="0 0 200 200">
                        <circle className="particle-node" cx="50" cy="50" r="5" />
                        <circle className="particle-node" cx="100" cy="100" r="5" />
                        <circle className="particle-node" cx="150" cy="50" r="5" />
                        <line x1="50" y1="50" x2="100" y2="100" className="particle-line" />
                        <line x1="100" y1="100" x2="150" y2="50" className="particle-line" />
                    </svg>
                </div>
            </nav>

            <section className="hero-section">
                {/* Unique Particle Logo Above Heading */}
                <div className="hero-logo-pattern">
                    <svg className="hero-logo-svg" viewBox="0 0 200 100">
                        <circle className="hero-logo-node" cx="40" cy="40" r="5" />
                        <circle className="hero-logo-node" cx="100" cy="50" r="5" />
                        <circle className="hero-logo-node" cx="160" cy="40" r="5" />
                    </svg>
                </div>

                <h1 className="hero-heading">Neutide Supplements</h1>
                <p className="hero-subheading">Empowering you with precision-crafted supplements for a balanced, powerful life.</p>
                <p className="hero-construction"> CURRENTLY UNDER CONSTRUCTION, PLEASE CHECK BACK LATER!</p>
                <p classname="hero-contact"> Meanwhile any queries or offers please email us at Neutidesupplements@proton.me</p>
            </section>

            <section className="product-section">
                <h2 className="section-heading">Our Products</h2>
                <ProductSlider />
            </section>\
        </div>
    );
}

export default App;
